import styled from 'styled-components';

export default styled.div`
  padding: 20px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.secondaryBackground};
  margin: 5px;
  margin-bottom: 16px;
  margin-top: 16px;
  box-shadow: 0 2px 3.84px rgba(0, 0, 0, 0.25);
`;
