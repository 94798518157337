import React from 'react';
import { Route } from 'react-router-dom';

import { ErrorBoundary } from '../../components';

import Create from './Create';
import List from './List';

const GainsRoutes: React.FC = () => (
  <ErrorBoundary>
    <Route path="/gains" exact component={List} />
    <Route path="/gains/create" exact component={Create} />
    <Route path="/gains/edit/:id" component={Create} />
  </ErrorBoundary>
);

export default GainsRoutes;
