import React, { ChangeEvent, FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Grid, ListItemText, MenuItem, Select, TextField, InputLabel } from '@material-ui/core';

import { Button, Container, Form, FormControl, Header, IconButton } from '../../../components';
import { useLoading } from '../../../contexts/loading';
import useFetch from '../../../hooks/useFetch';
import api from '../../../services/api';

import { Checkbox, FormFooter } from './styles';

const Create: React.FC = () => {
  const [selectedRole, setSelectedRole] = React.useState('ADMIN');
  const [selectedUnits, setSelectedUnits] = useState<string[]>([]);
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [pass, setPass] = useState<string>('');
  const [selectUnitIsDisabled, setSelectUnitIsDisabled] = useState(true);
  const [dataToSave, setDataToSave] = useState<{
    id: string | null;
    name: string;
    email: string;
    password: string;
    role: string;
    units: string[];
  }>();

  const { onLoading } = useLoading();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const { data: allUnits } = useFetch<{ id: string; name: string }[]>('units', {
    suspense: true,
  });

  const { data: userToEdit } = useFetch<{
    id: string;
    name: string;
    email: string;
    role: string;
    units: any[];
  }>(id ? `users/${id}` : null);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedRole(event.target.value as string);
    if (event.target.value !== 'ADMIN') {
      setSelectUnitIsDisabled(false);
      setSelectedUnits([]);
    } else {
      setSelectUnitIsDisabled(true);
    }
  };

  const handleChangeUnit = useCallback(
    (e: ChangeEvent<{ value: unknown }>) => {
      const values = e.target.value as string[];

      if (selectedRole === 'STUDENT' && values.length > 1) {
        values.pop();
      } else {
        const allValues = allUnits?.map((s) => s.id) || [];

        if (values.includes('-1') && values.length >= allValues.length) setSelectedUnits([]);
        else if (values.includes('-1')) setSelectedUnits(allValues);
        else setSelectedUnits(values);
      }
    },
    [allUnits, selectedRole],
  );

  useEffect(() => {
    if (userToEdit) {
      if (id) setName(userToEdit.name);
      setEmail(userToEdit.email);
      setSelectedRole(userToEdit.role);
      setSelectedUnits(userToEdit.units?.map((u) => u.id) || []);
    }
  }, [id, userToEdit]);

  useEffect(() => {
    setDataToSave({
      id: userToEdit?.id || null,
      name,
      email,
      password: pass,
      role: selectedRole,
      units: !selectUnitIsDisabled ? selectedUnits : [],
    });
    if (selectedRole !== 'ADMIN') setSelectUnitIsDisabled(false);
  }, [email, name, pass, selectUnitIsDisabled, selectedRole, selectedUnits, userToEdit?.id]);

  const handleNavigation = useCallback(() => {
    history.push('/users');
  }, [history]);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    try {
      onLoading(true);

      if (id) {
        await api.patch(`users/${id}`, dataToSave);
      } else {
        await api.post('users', dataToSave);
      }

      onLoading(false);
      toast.success('Salvo com sucesso!');
      handleNavigation();
    } catch (error) {
      onLoading(false);
      toast.error(`Falha ao salvar! Tente novamente! ${error}`);
    }
  };

  const isValid = useMemo(
    () =>
      name &&
      email &&
      pass &&
      selectedRole.length &&
      (selectedRole === 'ADMIN' ? true : selectedUnits.length),
    [name, email, pass, selectedRole, selectedUnits],
  );

  return (
    <Container>
      <Header title="Usuário">
        <IconButton onClick={handleNavigation}>
          <FiArrowLeft />
        </IconButton>
      </Header>
      <Form onSubmit={handleSubmit} autoComplete="off">
        <Grid container spacing={4}>
          <Grid item>
            <FormControl variant="outlined">
              <TextField
                id="name"
                value={name}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setName(e.target.value ? e.target.value : '')
                }
                label="Nome"
                variant="outlined"
                type="text"
                required
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl variant="outlined">
              <TextField
                id="email"
                value={email}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setEmail(e.target.value ? e.target.value : '')
                }
                label="E-mail"
                variant="outlined"
                type="email"
                required
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl variant="outlined">
              <TextField
                id="pass"
                value={pass}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setPass(e.target.value ? e.target.value : '')
                }
                label="Senha"
                variant="outlined"
                type="password"
                required
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl variant="outlined">
              <InputLabel id="demo-simple-select-outlined-label">Categoria</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={selectedRole}
                onChange={handleChange}
                label="Categoria"
              >
                <MenuItem value="">
                  <em>Selecione</em>
                </MenuItem>
                <MenuItem value="ADMIN">Administrador</MenuItem>
                <MenuItem value="STUDENT">Aluno</MenuItem>
                <MenuItem value="UNIT">Unidade</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <br />
          <Grid item>
            <FormControl variant="outlined">
              <InputLabel id="demo-simple-select-filled-label">Unidades</InputLabel>
              <Select
                id="units"
                multiple
                value={selectedUnits}
                defaultValue="Unidade"
                onChange={handleChangeUnit}
                label="Unidades"
                disabled={selectUnitIsDisabled}
                renderValue={(selected) =>
                  allUnits
                    ?.filter((s) => (selected as string[]).some((sel) => sel === s.id))
                    .map((s) => s.name)
                    .join(', ')
                }
              >
                <MenuItem value="-1" disabled={selectedRole === 'STUDENT'}>
                  <Checkbox checked={selectedUnits.length === allUnits?.length} />

                  <ListItemText primary="Todas" />
                </MenuItem>

                {allUnits?.map((st) => (
                  <MenuItem value={st.id} key={st.id}>
                    <Checkbox checked={selectedUnits.includes(st.id)} />

                    <ListItemText primary={st.name} />
                  </MenuItem>
                ))}
              </Select>
              <br />
            </FormControl>
          </Grid>
        </Grid>

        <FormFooter>
          <Button type="submit" disabled={!isValid}>
            Salvar
          </Button>
        </FormFooter>
      </Form>
    </Container>
  );
};

export default Create;
