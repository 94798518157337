import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { Layout, ErrorBoundary } from '../components';
import Home from '../pages/Home';
import { UsersRoutes } from '../pages/Users';

const UnitRoutes: React.FC = () => (
  <Layout>
    <Switch>
      <Route exact path="/">
        <Redirect to="/home" />
      </Route>
      <Route path="/users">
        <UsersRoutes />
      </Route>
      <Route
        path="/home"
        component={() => (
          <ErrorBoundary>
            <Home />
          </ErrorBoundary>
        )}
      />
      <Route path="/*">
        <Redirect to="/home" />
      </Route>
    </Switch>
  </Layout>
);

export default UnitRoutes;
