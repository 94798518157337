import React from 'react';

import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';

interface Props {
  isLoading: boolean;
}

const Loading = styled(CircularProgress)`
  position: absolute;
  color: ${(props) => props.theme.colors.primary};
  top: 50%;
  left: 50%;
  z-index: 10000;
`;

const LoadingContainer = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100000;
`;

const Spinner: React.FC<Props> = ({ isLoading }) =>
  isLoading ? (
    <LoadingContainer>
      <Loading size={50} disableShrink />
    </LoadingContainer>
  ) : null;

export default Spinner;
