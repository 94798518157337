export const dayOfWeek = [
  'Domingo',
  'Segunda-feira',
  'Terça-feira',
  'Quarta-feira',
  'Quinta-feira',
  'Sexta-feira',
  'Sábado',
];

export const configCurrency = { style: 'currency', currency: 'BRL' };

export const putZero = (value: number): string => {
  if (value > 9) return String(value);
  return `0${value}`;
};
