import React, { useMemo } from 'react';
import { FaRegUserCircle } from 'react-icons/fa';
import { FiMenu, FiMoon, FiSun } from 'react-icons/fi';
import { GoSignOut } from 'react-icons/go';

import {
  IconButton as IconButtonMU,
  Popover,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core';

import { useAuth } from '../../contexts/auth';
import { useDrawer } from '../../contexts/drawer';
import useTheme from '../../hooks/useTheme';

import { AppBar as Bar, IconButton, Toolbar, Typography, PopoverBody } from './styles';

const AppBar: React.FC = () => {
  const [isDark, setIsDark] = useTheme();

  const { open, openDrawer } = useDrawer();
  const { logout, user } = useAuth();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPopover = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const id = useMemo(() => (openPopover ? 'simple-popover' : undefined), [openPopover]);

  return (
    <Bar position="absolute" open={open}>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={openDrawer}
          open={open}
        >
          <FiMenu color="#FFF" />
        </IconButton>
        <Typography variant="h6" variantMapping={{ h1: 'h6' }} noWrap>
          Febracis
        </Typography>
        <IconButtonMU color="inherit" aria-describedby={id} onClick={() => setIsDark(!isDark)}>
          {isDark ? <FiMoon color="#FFF" /> : <FiSun color="#FFF" />}
        </IconButtonMU>
        <IconButtonMU color="inherit" aria-describedby={id} onClick={handleClick}>
          <FaRegUserCircle color="#FFF" size={32} />
        </IconButtonMU>
        <Popover
          id={id}
          open={openPopover}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <PopoverBody>
            <List>
              <ListItem>
                <ListItemText primary={<strong>{user?.name || ''}</strong>} />
              </ListItem>
              <Divider />
              <ListItem button onClick={() => logout()}>
                <ListItemIcon>
                  <GoSignOut size={24} />
                </ListItemIcon>
                <ListItemText primary="Sair" />
              </ListItem>
            </List>
          </PopoverBody>
        </Popover>
      </Toolbar>
    </Bar>
  );
};

export default AppBar;
