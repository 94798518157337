import {
  AppBar as AppBarMU,
  Toolbar as ToolbarMU,
  IconButton as IconButtonMU,
  Typography as TypographyMU,
} from '@material-ui/core';
import styled from 'styled-components';

interface Props {
  open: boolean;
}

export const AppBar = styled(AppBarMU)<Props>`
  z-index: 1201;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
    margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  background-color: ${(props) => props.theme.colors.primary} !important;

  ${({ open }) =>
    open &&
    `
    width: calc(100% - 260px);
    transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
      margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    margin-left: 260px;
  `}
`;

export const Toolbar = styled(ToolbarMU)`
  padding-right: 24px;
`;

export const IconButton = styled(IconButtonMU)<Props>`
  margin-right: 36px;
  ${({ open }) => open && `display: none;`}
`;

export const Typography = styled(TypographyMU)`
  flex-grow: 1;
  color: ${(props) => props.theme.colors.lightText};
`;

export const PopoverBody = styled.div`
  width: 200px;
  color: ${(props) => props.theme.colors.text};
  background: ${(props) => props.theme.colors.secondaryBackground};
  padding: 8px;
`;
