/* eslint-disable no-nested-ternary */
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState, useContext } from 'react';
import { FiCheck, FiEdit, FiLock } from 'react-icons/fi';
import { toast } from 'react-toastify';

import { Grid, Tooltip } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { ThemeContext } from 'styled-components';
import { mutate } from 'swr';

import { Container, Header, ButtonGroup, IconButton } from '../../../components';
import Table, { Column } from '../../../components/Table';
import { useLoading } from '../../../contexts/loading';
import useFetch from '../../../hooks/useFetch';
import api from '../../../services/api';

import { ModalAnwserMU } from './styles';

const List: React.FC = () => {
  const [refDate, setRefDate] = useState(new Date());
  const [selectedUnits, setSelectedUnits] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [selectedTask, setSelectedTask] = useState('');
  const [selectedTaskDetail, setSelectedTaskDetail] = useState<{
    title: string;
    description: string;
  }>({ title: '', description: '' });
  const [textAnwser, setTextAnwser] = useState<{
    taskId: string;
    observation: string;
    idAnwser: string;
  }>({
    taskId: '',
    observation: '',
    idAnwser: '',
  });
  const [isToday, setIsToday] = useState<boolean>(false);

  const { data: units } = useFetch<{ id: string; name: string }[]>('units', {
    suspense: true,
  });

  const { data: tasks } = useFetch<{ id: string; title: string }[]>(
    selectedUnits.length && refDate ? `tasks/list?date=${refDate.toISOString()}` : '',
  );

  // console.log(units);

  const handleDate = useCallback(() => {
    if (refDate.toISOString().split('T')[0] === new Date().toISOString().split('T')[0]) {
      setIsToday(true);
    } else {
      setIsToday(false);
    }
  }, [refDate]);

  useEffect(() => {
    handleDate();
  }, [handleDate, refDate]);

  const { onLoading } = useLoading();

  const {
    colors: { error, success },
  } = useContext(ThemeContext);

  const handleChangeDate = async (date: Date) => {
    setRefDate(date);
  };

  const handleTextAnwser = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setTextAnwser({
      taskId: selectedTask,
      observation: e.target.value,
      idAnwser: textAnwser.idAnwser,
    });
  };

  const handleChangePage = (_: any, newPage: number) => {
    setPage(newPage);
  };

  const handleClickOpenConfirmation = (taskId: string, observation?: string, idAnwser?: string) => {
    setOpenConfirmation(true);
    setSelectedTask(taskId);
    setTextAnwser({ taskId, observation: observation || '', idAnwser: idAnwser || '' });
  };

  const handleClose = () => {
    setOpenConfirmation(false);
    setSelectedTask('');
    setTextAnwser({ taskId: '', observation: '', idAnwser: '' });
  };

  const handleRemove = async () => {
    try {
      onLoading(true);

      textAnwser.idAnwser
        ? await api.patch(`answer/${textAnwser.idAnwser}`, {
            taskId: selectedTask,
            observation: textAnwser.observation,
          })
        : await api.post(`answer`, textAnwser);
      await mutate(`tasks/list?date=${refDate.toISOString()}`);

      onLoading(false);
      toast.success('Regitrado com sucesso!');
      handleClose();
    } catch (err) {
      handleClose();
      onLoading(false);
      toast.error(`Falha ao salvar! Tente novamente! ${err}`);
    }
  };

  useEffect(() => {
    const allValues = units?.map((u) => u.id) || [];
    setSelectedUnits(allValues);
  }, [units]);

  const columns = useMemo<Column[]>(
    () => [
      { key: 'title', name: 'Título', sort: true },
      { key: 'description', name: 'Descrição', sort: true },
      {
        key: 'answers',
        name: 'Resposta',
        accessor: ({ answers: answer }) => (
          <span>{answer?.map((u: { observation: string }) => u.observation)}</span>
        ),
      },
      {
        key: 'actions',
        name: 'Realizar',
        accessor: ({ id, ...rest }) => (
          <ButtonGroup>
            <Tooltip title="Editar atividade">
              {isToday && !rest.answers.length ? (
                <IconButton onClick={() => handleClickOpenConfirmation(id)} textcolor={error}>
                  <FiEdit />
                </IconButton>
              ) : isToday && rest.answers.length ? (
                <IconButton
                  onClick={() =>
                    handleClickOpenConfirmation(
                      id,
                      rest.answers[0]?.observation,
                      rest.answers[0]?.id,
                    )
                  }
                  textcolor={success}
                >
                  <FiCheck />
                </IconButton>
              ) : !isToday && rest.answers.length ? (
                <IconButton
                  onClick={() =>
                    handleClickOpenConfirmation(
                      id,
                      rest.answers[0]?.observation,
                      rest.answers[0]?.id,
                    )
                  }
                  textcolor={success}
                >
                  <FiCheck />
                </IconButton>
              ) : (
                <IconButton textcolor={error}>
                  <FiLock />
                </IconButton>
              )}
            </Tooltip>
          </ButtonGroup>
        ),
      },
    ],
    [error, isToday, success],
  );

  // console.log(columns);
  useEffect(() => {
    const taskDetails = tasks
      ? Object.values(tasks).find((elem) => elem.id === selectedTask)
      : undefined;
    if (taskDetails) {
      setSelectedTaskDetail({
        title: Object.values(taskDetails)[1],
        description: Object.values(taskDetails)[2],
      });
    }
  }, [selectedTask, tasks]);

  return (
    <Container>
      <Header title="Atividades" />

      <Grid container spacing={4}>
        <Grid item>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            id="contractSignDate"
            label="Data"
            value={refDate}
            onChange={(date) => handleChangeDate(date as Date)}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            required
            maxDate={new Date()}
          />
        </Grid>
      </Grid>

      <br />

      <Table
        rows={tasks || []}
        columns={columns}
        page={page}
        count={tasks?.length || 0}
        onChangePage={handleChangePage}
        pagination
      />

      <ModalAnwserMU
        title={selectedTaskDetail.title || ''}
        content={selectedTaskDetail.description || ''}
        open={openConfirmation}
        onSuccess={handleRemove}
        onClose={handleClose}
        value={textAnwser.observation}
        onChange={handleTextAnwser}
        textIsDisabled={!isToday}
      />
    </Container>
  );
};

export default List;
