import React from 'react';

import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 15%;
  }
`;

interface Props {
  title: string;
}

const Header: React.FC<Props> = ({ title, children }) => (
  <Container>
    <h1>{title}</h1>
    {children}
  </Container>
);

export default Header;
