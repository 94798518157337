import React, { Suspense } from 'react';

import { CssBaseline } from '@material-ui/core';

import { useLoading } from '../../contexts/loading';
import AppBar from '../AppBar';
import Drawer from '../Drawer';
import Spinner from '../Spinner';

import { AppContainer, Container, Content } from './styles';

const Layout: React.FC = ({ children }) => {
  const { loading } = useLoading();

  return (
    <Container>
      <CssBaseline />
      <AppBar />
      <Drawer />
      <Content>
        <AppContainer maxWidth="lg">
          <Spinner isLoading={loading} />
          <Suspense fallback={<Spinner isLoading />}>
            <>{children}</>
          </Suspense>
        </AppContainer>
      </Content>
    </Container>
  );
};

export default Layout;
