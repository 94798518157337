import React from 'react';

import { AuthProvider } from './auth';
import { DrawerProvider } from './drawer';
import { LoadingProvider } from './loading';

const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <DrawerProvider>
      <LoadingProvider>{children}</LoadingProvider>
    </DrawerProvider>
  </AuthProvider>
);

export default AppProvider;
