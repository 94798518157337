import React from 'react';

import styled from 'styled-components';

import img from '../../../assets/404.png';

const Image = styled.img`
  height: 350px;
  width: 350px;
`;

const NotFound: React.FC = () => (
  <>
    <h1>Oops!</h1>
    <h3>Não conseguimos nos conectar ao servidor, verifique sua conexão ou recarregue a página!</h3>
    <Image alt="404" src={img} />
  </>
);

export default NotFound;
