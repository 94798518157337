import React from 'react';
import { Route } from 'react-router-dom';

import { ErrorBoundary } from '../../components';

import List from './List';

const TasksStudentsRoutes: React.FC = () => (
  <ErrorBoundary>
    <Route path="/tasks" exact component={List} />
  </ErrorBoundary>
);

export default TasksStudentsRoutes;
