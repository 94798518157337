import React from 'react';

import { Roles } from '../@types/user.d';
import { Spinner } from '../components';
import { useAuth } from '../contexts/auth';

import AppRoutes from './app.routes';
import AuthRoutes from './auth.routes';
import UnitRoutes from './unit.routes';
import UserRoutes from './user.routes';

const Routes: React.FC = () => {
  const { signed, isLoading, user } = useAuth();

  if (isLoading) return <Spinner isLoading={isLoading} />;

  if (!signed) return <AuthRoutes />;

  if (user?.role === Roles.ADMIN) return <AppRoutes />;
  if (user?.role === Roles.STUDENT) return <UserRoutes />;
  if (user?.role === Roles.UNIT) return <UnitRoutes />;

  // Todo a 404 page
  return <AuthRoutes />;
};

export default Routes;
