import React, { PropsWithChildren } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';

interface Props {
  title: string;
  content?: string;
  open: boolean;
  onSuccess: () => void;
  onClose: () => void;
}

const ModalConfirm: React.FC<PropsWithChildren<Props>> = ({
  open,
  title,
  content,
  onSuccess,
  onClose,
  children,
}) => (
  <Dialog
    disableBackdropClick
    disableEscapeKeyDown
    maxWidth="xs"
    aria-labelledby="confirmation-dialog-title"
    open={open}
  >
    <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
    <DialogContent dividers>
      {content && <DialogContentText color="secondary">{content}</DialogContentText>}
      {children}
    </DialogContent>
    <DialogActions>
      <Button autoFocus onClick={onClose} color="secondary">
        Cancelar
      </Button>
      <Button onClick={onSuccess} color="primary" type="submit">
        Confirmar
      </Button>
    </DialogActions>
  </Dialog>
);

export default React.memo(ModalConfirm);
