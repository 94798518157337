import React, { useCallback, FormEvent, useState, ChangeEvent, useMemo, useEffect } from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  IconButton,
  Grid,
  TextField,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';

import { Button, Container, Form, Header } from '../../../components';
import { useLoading } from '../../../contexts/loading';
import useFetch from '../../../hooks/useFetch';
import api from '../../../services/api';

import { FormFooter, Checkbox, FormControl } from './styles';

const Create: React.FC = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [refDate, setRefDate] = useState(new Date());
  const [selectedUnits, setSelectedUnits] = useState<string[]>([]);

  const { onLoading } = useLoading();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const { data: units } = useFetch<{ id: string; name: string }[]>('units', {
    suspense: true,
  });

  const { data: taskToEdit } = useFetch<{
    id: string;
    title: string;
    description: string;
    date: Date;
    units: any[];
  }>(id ? `tasks/${id}` : null);

  const handleNavigation = useCallback(() => {
    history.push('/tasks');
  }, [history]);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    const task = {
      id,
      title,
      description,
      date: refDate,
      units: selectedUnits.map((u) => units?.find((un) => un.id === u)),
    };

    try {
      onLoading(true);

      if (id) {
        await api.patch(`tasks/${id}`, task);
      } else {
        await api.post('tasks', task);
      }

      onLoading(false);
      toast.success('Salvo com sucesso!');
      handleNavigation();
    } catch (error) {
      onLoading(false);
      toast.error(`Falha ao salvar! Tente novamente! ${error}`);
    }
  };

  const handleChangeUnits = useCallback(
    (e: ChangeEvent<{ value: unknown }>) => {
      const values = e.target.value as string[];
      const allValues = units?.map((u) => u.id) || [];

      if (values.includes('-1') && values.length >= allValues.length) setSelectedUnits([]);
      else if (values.includes('-1')) setSelectedUnits(allValues);
      else setSelectedUnits(values);
    },
    [units],
  );

  const isValid = useMemo(() => title && description && refDate && selectedUnits.length, [
    description,
    refDate,
    selectedUnits.length,
    title,
  ]);

  useEffect(() => {
    if (taskToEdit) {
      setTitle(taskToEdit.title || '');
      setDescription(taskToEdit.description || '');
      setRefDate(taskToEdit.date || new Date());
      setSelectedUnits(taskToEdit.units?.map((u) => u.id) || []);
    }
  }, [taskToEdit]);

  return (
    <Container>
      <Header title="Cadastrar Atividade">
        <IconButton onClick={handleNavigation}>
          <FiArrowLeft />
        </IconButton>
      </Header>
      <Form onSubmit={handleSubmit} autoComplete="off">
        <Grid container spacing={4}>
          <Grid item>
            <TextField
              id="title"
              label="Título"
              variant="outlined"
              value={title}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setTitle(e.target.value)}
              required
            />
          </Grid>
          <Grid item>
            <TextField
              id="description"
              label="Descrição"
              variant="outlined"
              multiline
              rows="5"
              rowsMax="10"
              value={description}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)}
              required
            />
          </Grid>
          <Grid item>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              id="contractSignDate"
              label="Data"
              value={refDate}
              onChange={(date) => setRefDate(date as Date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              required
              maxDate={new Date()}
            />
          </Grid>
          <Grid item>
            <FormControl variant="outlined">
              <InputLabel id="store">Unidade</InputLabel>
              <Select
                id="units"
                multiple
                value={selectedUnits}
                onChange={handleChangeUnits}
                input={<Input />}
                label="Unidade"
                renderValue={(selected) =>
                  units
                    ?.filter((u) => (selected as string[]).some((sel) => sel === u.id))
                    .map((u) => u.name)
                    .join(', ')
                }
                required
              >
                <MenuItem value="-1">
                  <Checkbox checked={selectedUnits.length === units?.length} />

                  <ListItemText primary="Todas" />
                </MenuItem>

                {units?.map((st) => (
                  <MenuItem value={st.id} key={st.id}>
                    <Checkbox checked={selectedUnits.includes(st.id)} />

                    <ListItemText primary={st.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <FormFooter>
          <Button type="submit" disabled={!isValid}>
            Salvar
          </Button>
        </FormFooter>
      </Form>
    </Container>
  );
};

export default Create;
