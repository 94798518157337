import React, { PropsWithChildren } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  OutlinedInputProps,
} from '@material-ui/core';

interface Props {
  title: string;
  content?: string;
  open: boolean;
  value: string;
  textIsDisabled: boolean;
  onSuccess: () => void;
  onClose: () => void;
  onChange?: OutlinedInputProps['onChange'];
}

const ModalConfirm: React.FC<PropsWithChildren<Props>> = ({
  open,
  title,
  content,
  value,
  onSuccess,
  textIsDisabled,
  onClose,
  onChange,
  children,
}) => (
  <Dialog
    disableBackdropClick
    disableEscapeKeyDown
    maxWidth="xs"
    aria-labelledby="confirmation-dialog-title"
    open={open}
  >
    <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
    <DialogContent dividers>
      {content && <DialogContentText color="secondary">{content}</DialogContentText>}
      {children}
      <br />
      <form>
        <TextField
          id="standard-basic"
          value={value}
          onChange={onChange}
          disabled={textIsDisabled}
          required
        />
      </form>
    </DialogContent>
    <DialogActions>
      <Button autoFocus onClick={onClose} color="secondary">
        FECHAR
      </Button>
      {!textIsDisabled ? (
        <Button onClick={onSuccess} color="primary" type="submit">
          Salvar
        </Button>
      ) : (
        <span />
      )}
    </DialogActions>
  </Dialog>
);

export default React.memo(ModalConfirm);
