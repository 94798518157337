import React from 'react';
import { Route } from 'react-router-dom';

import { ErrorBoundary } from '../../components';

import Create from './Create';
import List from './List';

const UnitsRoutes: React.FC = () => (
  <ErrorBoundary>
    <Route path="/units" exact component={List} />
    <Route path="/units/create" exact component={Create} />
    <Route path="/units/edit/:id" component={Create} />
  </ErrorBoundary>
);

export default UnitsRoutes;
