import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import DateFnsUtils from '@date-io/date-fns';
import { StylesProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ptBR } from 'date-fns/locale';
import { ThemeProvider } from 'styled-components';

import AppProvider from './contexts';
import useTheme from './hooks/useTheme';
import Routes from './routes';
import GlobalStyle from './styles/global';
import dark from './styles/themes/dark';
import light from './styles/themes/light';

toast.configure({
  position: 'top-right',
  autoClose: 5000,
});
const App: React.FC = () => {
  const [isDark] = useTheme();

  return (
    <StylesProvider injectFirst>
      <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
        <ThemeProvider theme={isDark ? dark : light}>
          <GlobalStyle />
          <BrowserRouter>
            <AppProvider>
              <Routes />
            </AppProvider>
          </BrowserRouter>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </StylesProvider>
  );
};

export default App;
