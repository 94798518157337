import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { Layout, ErrorBoundary } from '../components';
import { GainsRoutes } from '../pages/Gains';
import Home from '../pages/Home';
import { TasksStudentsRoutes } from '../pages/TasksStudents';

const UserRoutes: React.FC = () => (
  <Layout>
    <Switch>
      <Route exact path="/">
        <Redirect to="/home" />
      </Route>
      <Route path="/tasks">
        <TasksStudentsRoutes />
      </Route>
      <Route path="/gains">
        <GainsRoutes />
      </Route>
      <Route
        path="/home"
        component={() => (
          <ErrorBoundary>
            <Home />
          </ErrorBoundary>
        )}
      />
      <Route path="/*">
        <Redirect to="/home" />
      </Route>
    </Switch>
  </Layout>
);

export default UserRoutes;
