import { Button as Btn } from '@material-ui/core';
import styled from 'styled-components';

interface ButtonProps {
  bgcolor?: string;
  textcolor?: string;
  component?: string;
}

export default styled(Btn)<ButtonProps>`
  background-color: ${(props) => props.bgcolor || props.theme.colors.primary};
  color: ${(props) => props.textcolor || props.theme.colors.lightText};
  :hover {
    background-color: ${(props) => props.bgcolor || props.theme.colors.primary};
    filter: brightness(90%);
  }
  ${(props) =>
    props.disabled &&
    `
    background-color: #E0E0E0;
  `}
`;
