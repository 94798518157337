import React, { useCallback, FormEvent, useState, ChangeEvent, useMemo, useEffect } from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { IconButton, Grid, TextField } from '@material-ui/core';

import { Button, Container, Form, Header } from '../../../components';
import { useLoading } from '../../../contexts/loading';
import useFetch from '../../../hooks/useFetch';
import api from '../../../services/api';

import { FormFooter } from './styles';

const Create: React.FC = () => {
  const [name, setName] = useState('');

  const { onLoading } = useLoading();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const { data: unitToEdit } = useFetch<{
    id: string;
    name: string;
  }>(id ? `units/${id}` : null);

  const handleNavigation = useCallback(() => {
    history.push('/units');
  }, [history]);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    const unit = {
      id,
      name,
    };

    try {
      onLoading(true);

      if (id) {
        await api.patch(`units/${id}`, unit);
      } else {
        await api.post('units', unit);
      }

      onLoading(false);
      toast.success('Salvo com sucesso!');
      handleNavigation();
    } catch (error) {
      onLoading(false);
      toast.error(`Falha ao salvar! Tente novamente! ${error}`);
    }
  };

  const isValid = useMemo(() => !!name, [name]);

  useEffect(() => {
    if (unitToEdit) {
      setName(unitToEdit.name || '');
    }
  }, [unitToEdit]);

  return (
    <Container>
      <Header title="Cadastrar Unidade">
        <IconButton onClick={handleNavigation}>
          <FiArrowLeft />
        </IconButton>
      </Header>
      <Form onSubmit={handleSubmit} autoComplete="off">
        <Grid container spacing={4}>
          <Grid item>
            <TextField
              id="title"
              label="Título"
              variant="outlined"
              value={name}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
              required
            />
          </Grid>
        </Grid>
        <FormFooter>
          <Button type="submit" disabled={!isValid}>
            Salvar
          </Button>
        </FormFooter>
      </Form>
    </Container>
  );
};

export default Create;
