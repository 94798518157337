export const chartData2 = [
  {
    unit: 'Fortaleza',
    done: 0,
    notDone: 38,
    month: 'Jan',
  },
  {
    unit: 'Rio de Janeiro',
    done: 40,
    notDone: 20,
    month: 'Fev',
  },
  {
    unit: 'Curitiba',
    done: 15,
    notDone: 55,
    month: 'Mar',
  },
  {
    unit: 'São Paulo',
    done: 45,
    notDone: 8,
    month: 'Abr',
  },
];

export const datinha = [
  {
    month: 'Jan',
    sp: 19,
    fortal: 0,
    cu: 10,
  },
  {
    month: 'Fev',
    sp: 13,
    fortal: 10,
    cu: 23,
  },
  {
    month: 'Mar',
    sp: 14,
    fortal: 35,
    cu: 30,
  },
  {
    month: 'Abr',
    sp: 13,
    fortal: 32,
    cu: 34,
  },
  {
    month: 'Mai',
    sp: 15,
    fortal: 28,
    cu: 32,
  },
];

export const modulePercent = [
  {
    month: 'Jan',
    sp: 4,
    fortal: 10,
    cu: 15,
  },
  {
    month: 'Fev',
    sp: 7,
    fortal: 18,
    cu: 23,
  },
  {
    month: 'Mar',
    sp: 14,
    fortal: 40,
    cu: 30,
  },
  {
    month: 'Abr',
    sp: 13,
    fortal: 25,
    cu: 34,
  },
  {
    month: 'Mai',
    sp: 15,
    fortal: 28,
    cu: 32,
  },
];

export const chartData = [
  {
    unit: 'Fortaleza',
    done: 0,
    notDone: 38,
    gain: 50000,
  },
  {
    unit: 'Rio de Janeiro',
    done: 40,
    notDone: 20,
    gain: 150000,
  },
  {
    unit: 'Curitiba',
    done: 15,
    notDone: 55,
    gain: 20000,
  },
  {
    unit: 'São Paulo',
    done: 45,
    notDone: 8,
    gain: 250000,
  },
];

export const chartDataGainStudents = [
  {
    unit: 'Fortaleza',
    day: 6,
    month: 38,
  },
  {
    unit: 'Rio de Janeiro',
    day: 4,
    month: 20,
  },
  {
    unit: 'Curitiba',
    day: 5,
    month: 55,
  },
  {
    unit: 'São Paulo',
    day: 2,
    month: 38,
  },
];

export const confidence = [
  {
    year: 1993,
    tvNews: 19,
    church: 29,
    military: 32,
  },
  {
    year: 1995,
    tvNews: 13,
    church: 32,
    military: 33,
  },
  {
    year: 1997,
    tvNews: 14,
    church: 35,
    military: 30,
  },
  {
    year: 1999,
    tvNews: 13,
    church: 32,
    military: 34,
  },
  {
    year: 2001,
    tvNews: 15,
    church: 28,
    military: 32,
  },
  {
    year: 2003,
    tvNews: 16,
    church: 27,
    military: 48,
  },
  {
    year: 2006,
    tvNews: 12,
    church: 28,
    military: 41,
  },
  {
    year: 2008,
    tvNews: 11,
    church: 26,
    military: 45,
  },
  {
    year: 2010,
    tvNews: 10,
    church: 25,
    military: 44,
  },
  {
    year: 2012,
    tvNews: 11,
    church: 25,
    military: 43,
  },
  {
    year: 2014,
    tvNews: 10,
    church: 25,
    military: 39,
  },
  {
    year: 2016,
    tvNews: 8,
    church: 20,
    military: 41,
  },
  {
    year: 2018,
    tvNews: 10,
    church: 20,
    military: 43,
  },
];
