import React from 'react';

import { Animation, LineSeries, Stack, ValueScale } from '@devexpress/dx-react-chart';
import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  BarSeries,
  Title,
  Legend,
  Tooltip,
} from '@devexpress/dx-react-chart-material-ui';

import { Container } from '../../components';

import { chartData, chartDataGainStudents, datinha, modulePercent } from './mockData/index.js';

// const format = () => (tick: any) => tick;

const Home: React.FC = () => (
  <Container>
    <Chart data={chartData}>
      <ValueScale name="y" />
      <ValueScale name="y2" />
      <ArgumentAxis />
      <ValueAxis scaleName="y" />
      <ValueAxis scaleName="y2" position="right" />

      <BarSeries
        name="Finalizadas"
        valueField="done"
        argumentField="unit"
        color="#2a9d8f"
        scaleName="y"
      />
      <BarSeries
        name="Não Finalizadas"
        valueField="notDone"
        argumentField="unit"
        color="#e63946"
        scaleName="y"
      />
      <BarSeries
        name="Ganhos"
        valueField="gain"
        argumentField="unit"
        color="#7bdff2"
        scaleName="y2"
      />

      <Animation />
      <Legend position="bottom" />
      <Title text="Atividades Finalizadas x Ganho" />
      <Tooltip />
      <Stack />
    </Chart>

    <Chart data={datinha}>
      <ArgumentAxis />
      <ValueAxis />
      <Legend position="bottom" />
      <Title text="% de cumprimento de agenda programada" />
      <LineSeries valueField="fortal" argumentField="month" name="Fortaleza" />
      <LineSeries valueField="sp" argumentField="month" name="São Paulo" />
      <LineSeries valueField="cu" argumentField="month" name="Curutiba" />
      <ValueAxis scaleName="month" />
    </Chart>

    <Chart data={chartData}>
      <ValueScale name="y" />
      <ValueScale name="y2" />
      <ArgumentAxis />
      <ValueAxis scaleName="y" />
      <ValueAxis scaleName="y2" position="right" />

      <BarSeries
        name="Presentes"
        valueField="done"
        argumentField="unit"
        color="#2a9d8f"
        scaleName="y"
      />
      <BarSeries
        name="Confirmados"
        valueField="notDone"
        argumentField="unit"
        color="#e63946"
        scaleName="y"
      />
      <BarSeries
        name="Evasão"
        valueField="gain"
        argumentField="unit"
        color="#7bdff2"
        scaleName="y2"
      />

      <Animation />
      <Legend position="bottom" />
      <Title text="Aderência nos encontros presenciais" />
      <Tooltip />
      <Stack />
    </Chart>

    <Chart data={chartDataGainStudents}>
      <ValueScale name="y" />
      <ArgumentAxis />
      <ValueAxis scaleName="y" />

      <BarSeries name="Dia" valueField="day" argumentField="unit" color="#e63946" scaleName="y" />
      <BarSeries name="Mês" valueField="month" argumentField="unit" color="#7bdff2" scaleName="y" />

      <Animation />
      <Legend position="bottom" />
      <Title text="Faturamento de alunos" />
      <Tooltip />
      <Stack />
    </Chart>

    <Chart data={modulePercent}>
      <ArgumentAxis />
      <ValueAxis />
      <Legend position="bottom" />
      <Title text="% de cumprimento dos módulos de conteúdo" />
      <LineSeries valueField="fortal" argumentField="month" name="Fortaleza" />
      <LineSeries valueField="sp" argumentField="month" name="São Paulo" />
      <LineSeries valueField="cu" argumentField="month" name="Curutiba" />
      <ValueAxis scaleName="month" />
    </Chart>
  </Container>
);

export default Home;
