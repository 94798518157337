import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

body {
  background: ${(props) => props.theme.colors.background};
  -webkit-font-smoothing: antialiased;
  color: ${(props) => props.theme.colors.text};
}

body, input, button {
  font-family: Roboto, Arial, Helvetica, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  color: ${(props) => props.theme.colors.text};
  font-family: Roboto;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active,
input:-internal-autofill-selected
{
 -webkit-box-shadow: 0 0 0 30px ${(props) => props.theme.colors.background} inset !important;
 -webkit-text-fill-color: ${(props) => props.theme.colors.text} !important;
}

button, .MuiIconButton-root, .MuiListItemIcon-root, .MuiTableCell-head, .MuiTableCell-body, 
.MuiPaper-root, .MuiInputBase-input, .MuiInput-input, .MuiToolbar-root, .MuiStepper-root, 
.MuiStepLabel-label.MuiStepLabel-active, .MuiFormLabel-root, .MuiSelect-icon, 
.MuiTypography-colorTextSecondary, .MuiTypography-colorSecondary {
  color: ${(props) => props.theme.colors.text};
}

.Mui-disabled, .MuiIconButton-root.Mui-disabled, .MuiTableSortLabel-root:hover, .MuiStepLabel-label, 
.MuiStepLabel-iconContainer, .MuiInputBase-root.Mui-disabled, .MuiFormLabel-root.Mui-disabled,
.MuiSelect-icon.Mui-disabled, .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline, 
.MuiFormControlLabel-label.Mui-disabled {
  color: ${(props) => props.theme.colors.disabled};
  border-color: ${(props) => props.theme.colors.disabled};
}

.MuiStepper-root, .MuiPaper-root {
  background: ${(props) => props.theme.colors.secondaryBackground};
}

.MuiInput-underline:before, .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom-color: ${(props) => props.theme.colors.primary};
}

.MuiDrawer-paperAnchorDockedLeft, .MuiTableCell-root {
  border-color: ${(props) => props.theme.colors.border};
}

.MuiOutlinedInput-notchedOutline {
  border-color: ${(props) => props.theme.colors.text};
}

.MuiIconButton-root:hover {
  background-color: ${(props) => props.theme.colors.ripple};
}

.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover {
  background-color: ${(props) => props.theme.colors.itemSelected};
}

.highlightLine {
  :hover {
    background-color: ${(props) => props.theme.colors.itemSelected};
  }
  a {
    width: auto;
  }
}

.MuiPickersDay-daySelected {
  color: ${(props) => props.theme.colors.lightText};
}

.MuiPickersCalendarHeader-dayLabel, .MuiListSubheader-root {
  color: ${(props) => props.theme.colors.disabled};
}

.MuiPickersCalendarHeader-iconButton {
  background: inherit;
}

textarea {
  background-color: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.text};

  :focus {
    outline: ${(props) => props.theme.colors.primary} auto 1px;
  }
}

.MuiFormLabel-root.Mui-focused {
  color: ${(props) => props.theme.colors.primary};
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: ${(props) => props.theme.colors.primary};
}
`;
