import React, { MouseEvent, ChangeEvent, useMemo, ReactElement } from 'react';

import {
  Table as TableMU,
  TableBody,
  TableHead,
  TableRow as TableRowMU,
  TableCell,
  TablePagination,
  TableFooter,
  TableSortLabel,
  TableContainer,
  Paper,
} from '@material-ui/core';

export interface Column {
  key: string;
  name: string;
  accessor?: (row: any) => string | ReactElement;
  sort?: boolean;
}

interface Props {
  rows: any[];
  columns: Column[];
  pagination?: boolean;
  page?: number;
  count?: number;
  onChangePage?: (event: MouseEvent<HTMLButtonElement> | null, page: number) => void;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const TableRow = React.memo(TableRowMU);

const Table: React.FC<Props> = ({ rows, columns, pagination, onChangePage, page, count }) => {
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState('');

  const orderedRows = useMemo(() => stableSort(rows, getComparator(order, orderBy)), [
    rows,
    order,
    orderBy,
  ]);

  const rowsToShow = useMemo(
    () =>
      pagination
        ? orderedRows.slice((page || 0) * rowsPerPage, (page || 0) * rowsPerPage + rowsPerPage)
        : orderedRows,
    [pagination, orderedRows, page, rowsPerPage],
  );

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    onChangePage && onChangePage(null, 0);
  };

  const handleRequestSort = (property: string) => {
    const column = columns.find((col) => col.key === property);

    if (!column?.sort) return;

    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <TableContainer component={Paper}>
      <TableMU>
        <TableHead>
          <TableRow>
            {columns.map((col) => (
              <TableCell key={col.key}>
                {col.sort ? (
                  <TableSortLabel
                    active={orderBy === col.key}
                    direction={orderBy === col.key ? order : 'asc'}
                    onClick={() => handleRequestSort(col.key)}
                  >
                    {col.name}
                  </TableSortLabel>
                ) : (
                  col.name
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rowsToShow?.map((row) => (
            <TableRow key={row.id}>
              {columns.map((col) => (
                <TableCell key={col.key}>
                  {col.accessor ? col.accessor(row) : row[col.key]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
        {pagination && onChangePage && (
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 15, 20, 50]}
                rowsPerPage={rowsPerPage}
                count={count || 0}
                page={page || 0}
                onChangePage={onChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage="Quantidade por página"
              />
            </TableRow>
          </TableFooter>
        )}
      </TableMU>
    </TableContainer>
  );
};

export default React.memo(Table);
