import React, { useContext } from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-use';

import {
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@material-ui/core';
import { ThemeContext } from 'styled-components';

import logo from '../../assets/varejo_inteligente.png';
import { useDrawer } from '../../contexts/drawer';
import useMenu from '../../hooks/useMenu';

import { Container, ToolbarIcon } from './styles';

const Drawer: React.FC = () => {
  const { open, closeDrawer } = useDrawer();
  const screens = useMenu();
  const history = useHistory();
  const location = useLocation();
  const theme = useContext(ThemeContext);

  const handleNavigation = (path: string) => {
    history.push(path);
  };

  return (
    <Container variant="permanent" elevation={3} open={open}>
      <ToolbarIcon>
        <img alt="varejo inteligente" src={logo} />
        <IconButton onClick={closeDrawer}>
          <FiChevronLeft />
        </IconButton>
      </ToolbarIcon>
      <Divider />
      <List>
        {screens.map(({ name, path, Icon }) => (
          <Tooltip key={name} title={name} placement="right">
            <ListItem
              button
              onClick={() => handleNavigation(path)}
              selected={location.pathname?.includes(path)}
            >
              <ListItemIcon>
                <Icon size={24} color={theme.colors.text} />
              </ListItemIcon>
              <ListItemText primary={name} />
            </ListItem>
          </Tooltip>
        ))}
      </List>
    </Container>
  );
};

export default React.memo(Drawer);
