import React, { useCallback, FormEvent, useState, ChangeEvent, useMemo, useEffect } from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { IconButton, Grid, TextField, InputLabel, Input, InputAdornment } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';

import { Button, Container, Form, FormControl, Header } from '../../../components';
import { useLoading } from '../../../contexts/loading';
import useFetch from '../../../hooks/useFetch';
import api from '../../../services/api';

import { FormFooter } from './styles';

const Create: React.FC = () => {
  const [description, setDescription] = useState('');
  const [value, setValue] = useState<number | ''>(0);
  const [refDate, setRefDate] = useState(new Date());

  const { onLoading } = useLoading();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const { data: gainToEdit } = useFetch<{
    id: string;
    description: string;
    value: number;
    date: Date;
  }>(id ? `gains/${id}` : null);

  const handleNavigation = useCallback(() => {
    history.push('/gains');
  }, [history]);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    const gain = {
      id,
      description,
      value,
      date: refDate,
    };

    try {
      onLoading(true);

      if (id) {
        await api.patch(`gains/${id}`, gain);
      } else {
        await api.post('gains', gain);
      }

      onLoading(false);
      toast.success('Salvo com sucesso!');
      handleNavigation();
    } catch (error) {
      onLoading(false);
      toast.error(`Falha ao salvar! Tente novamente! ${error}`);
    }
  };

  const isValid = useMemo(() => description && value > 0 && refDate, [description, refDate, value]);

  useEffect(() => {
    if (gainToEdit) {
      setDescription(gainToEdit.description || '');
      setRefDate(gainToEdit.date || '');
      setValue(gainToEdit.value || '');
    }
  }, [gainToEdit]);

  return (
    <Container>
      <Header title="Cadastrar Ganho">
        <IconButton onClick={handleNavigation}>
          <FiArrowLeft />
        </IconButton>
      </Header>
      <Form onSubmit={handleSubmit} autoComplete="off">
        <Grid container spacing={4}>
          <Grid item>
            <TextField
              id="title"
              label="Título"
              variant="outlined"
              value={description}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)}
              required
            />
          </Grid>
          <Grid item>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              id="refDate"
              label="Data"
              value={refDate}
              onChange={(date) => setRefDate(date as Date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              required
              maxDate={new Date()}
            />
          </Grid>
          <Grid item>
            <FormControl>
              <InputLabel htmlFor="value">Valor</InputLabel>
              <Input
                id="value"
                value={value}
                startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                onFocus={(e) => e.target.select()}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setValue(e.target.value ? Number(e.target.value) : '')
                }
                type="number"
                required
              />
            </FormControl>
          </Grid>
        </Grid>
        <FormFooter>
          <Button type="submit" disabled={!isValid}>
            Salvar
          </Button>
        </FormFooter>
      </Form>
    </Container>
  );
};

export default Create;
