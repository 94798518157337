import { shade, darken } from 'polished';
import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  name: 'dark',

  colors: {
    primary: '#B3494A',
    secondary: '#E89E64',

    text: '#FFF',
    lightText: '#FFF',

    background: '#222',
    secondaryBackground: '#363636',

    success: '#67E480',
    error: '#E96379',
    warning: '#E7DE79',
    info: '#78D1E1',
    default: '#A1A6AB',
    disabled: shade(0.3, '#E1E1E6'),

    border: darken(0.2, '#B3494A'),
    ripple: '#0000001a',
    itemSelected: '#aaaaaa1a',
  },
};

export default theme;
