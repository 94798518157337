import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  name: 'light',

  colors: {
    primary: '#B3494A',
    secondary: '#BD9E29',
    text: '#333',
    lightText: '#FFF',

    background: '#F0F0F5',
    secondaryBackground: '#FFF',

    success: '#2a9d8f',
    error: '#e63946',
    warning: '#ffc107',
    info: '#7bdff2',
    default: '#C7D3DD',
    disabled: '#0000004d',

    border: '#0000001f',
    ripple: '#0000000a',
    itemSelected: 'rgba(0, 0, 0, 0.08)',
  },
};

export default theme;
