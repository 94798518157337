/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { ReactNode, Component, PropsWithChildren } from 'react';

import NotFound from './components/404';

interface State {
  error: any;
  errorInfo: any;
}

export default class ErrorBoundary extends Component<PropsWithChildren<any>, State> {
  constructor(props: PropsWithChildren<any>) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: any, errorInfo: any): void {
    this.setState({ error, errorInfo });
  }

  componentWillUnmount() {
    this.setState({ error: null, errorInfo: null });
  }

  render(): ReactNode {
    const { error, errorInfo } = this.state;
    const { children } = this.props;

    if (errorInfo && error) {
      return <NotFound />;
    }

    return children;
  }
}
