import React, { useState, FormEvent, ChangeEvent } from 'react';

import { User } from '../../@types/user';
import logo from '../../assets/varejo_inteligente.png';
import { Input } from '../../components';
import { useAuth } from '../../contexts/auth';

import { Container, Form, FormContainer, FormTitle, Button } from './styles';

const Login: React.FC = () => {
  const { login } = useAuth();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async (event: FormEvent) => {
    event.preventDefault();

    await login({ username, password } as User);
  };

  const handleChangeUsername = (e: ChangeEvent<HTMLInputElement>) => setUsername(e.target.value);

  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value);

  return (
    <Container style={{ background: '#222' }}>
      <FormContainer>
        <Form onSubmit={handleLogin}>
          <FormTitle>
            <img alt="logo" src={logo} style={{ width: '100%' }} />
          </FormTitle>
          <Input
            type="text"
            placeholder="Usuário"
            value={username}
            onChange={handleChangeUsername}
            required
          />
          <Input
            type="password"
            placeholder="Senha"
            value={password}
            onChange={handleChangePassword}
            required
          />
          <Button type="submit">Entrar</Button>
        </Form>
      </FormContainer>
    </Container>
  );
};

export default Login;
