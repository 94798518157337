import { Paper } from '@material-ui/core';
import styled from 'styled-components';

export default styled(Paper)`
  padding: 16px;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.colors.secondaryBackground} !important;
  color: ${(props) => props.theme.colors.text};
`;
