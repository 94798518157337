import { FormControl as FormControlMU, Checkbox as CheckboxMU } from '@material-ui/core';
import styled from 'styled-components';

export const Checkbox = styled(CheckboxMU)`
  color: ${(props) => props.theme.colors.primary} !important;
`;

export const FormControl = styled(FormControlMU)`
  min-width: 210px;
  max-width: 210px;
`;
