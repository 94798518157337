import styled from 'styled-components';

interface Props {
  color: string;
}

export default styled.span<Props>`
  color: #fff;
  background-color: ${({ color }) => color};
  height: 20px;
  display: flex;
  padding: 0 6px;
  z-index: 1;
  width: fit-content;
  flex-wrap: wrap;
  font-size: 0.75rem;
  min-width: 20px;
  box-sizing: border-box;
  transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  align-items: center;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  line-height: 1;
  align-content: center;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
`;
