import React, { createContext, useContext } from 'react';

import usePersistedState from '../hooks/usePersistedState';

interface DrawerContextData {
  open: boolean;
  openDrawer(): void;
  closeDrawer(): void;
}

const DrawerContext = createContext<DrawerContextData>({} as DrawerContextData);

export const DrawerProvider: React.FC = ({ children }) => {
  const [open, setOpen] = usePersistedState('drawer', false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <DrawerContext.Provider
      value={{
        open,
        closeDrawer: handleDrawerClose,
        openDrawer: handleDrawerOpen,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
};

export const useDrawer = (): DrawerContextData => {
  const context = useContext(DrawerContext);

  return context;
};
