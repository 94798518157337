import styled from 'styled-components';

export default styled.input`
  width: 100%;
  height: 60px;
  color: ${(props) => props.theme.colors.text};
  border: 1px solid ${(props) => props.theme.colors.primary};
  background: ${(props) => props.theme.colors.background};
  border-radius: 8px;
  padding: 0 24px;
  margin-top: 8px;
  font: 400 18px Roboto, sans-serif;
`;
