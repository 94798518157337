import { Container as ContainerMU } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const Content = styled.main`
  flex-grow: 1;
  overflow: auto;
  height: calc(100vh - 56px);
  margin-top: 56px;

  @media (min-width: 0px) and (orientation: landscape) {
    height: calc(100vh - 48px);
    margin-top: 48px;
  }

  @media (min-width: 600px) {
    height: calc(100vh - 64px);
    margin-top: 64px;
  }
`;

export const AppContainer = styled(ContainerMU)`
  padding-top: 16px;
  padding-bottom: 16px;
`;
