// eslint-disable-next-line no-shadow
export enum Roles {
  ADMIN = 'ADMIN',
  STUDENT = 'STUDENT',
  UNIT = 'UNIT',
}

export interface User {
  id?: number;
  name: string;
  username: string;
  password?: string;
  role?: Roles;
}
