import React from 'react';
import { Route } from 'react-router-dom';

import { ErrorBoundary } from '../../components';

import Create from './Create';
import List from './List';

const TasksRoutes: React.FC = () => (
  <ErrorBoundary>
    <Route path="/tasks" exact component={List} />
    <Route path="/tasks/create" exact component={Create} />
    <Route path="/tasks/edit/:id" component={Create} />
  </ErrorBoundary>
);

export default TasksRoutes;
