import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin: 10px 0;
  padding: 10px;
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
  span {
    margin-left: 10px;
    text-overflow: ellipsis;
  }
`;
