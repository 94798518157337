import React, { createContext, useState, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useEffectOnce } from 'react-use';

import { AxiosResponse } from 'axios';
import { cache } from 'swr';

import { User } from '../@types/user';
import usePersistedState from '../hooks/usePersistedState';
import api from '../services/api';

interface AuthContextData {
  signed: boolean;
  isLoading: boolean;
  user: User | null;
  login(user: User): Promise<void>;
  logout(): void;
}

interface LoginResponse {
  accessToken: string;
  user: User;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = usePersistedState<User | null>('user', null);
  const [token, setToken] = usePersistedState<string>('token', '');
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const login = async ({ username, password }: User) => {
    try {
      setIsLoading(true);
      const response = await api.post<LoginResponse>('login', {
        username,
        password,
      });
      const { data } = response;

      setUser(data.user);
      setToken(data.accessToken);
    } catch (error) {
      if (error.response?.status) {
        toast.error('Login e/ou senha incorretos!');
      } else {
        toast.error(error.message);
      }
    }
    setIsLoading(false);
  };

  const logout = () => {
    setToken('');
    setUser(null);
    cache.clear();
    api.defaults.headers.Authorization = null;
  };

  useEffect(() => {
    if (token) {
      api.defaults.headers.Authorization = `Bearer ${token}`;
    }
    setIsLoading(false);
  }, [token]);

  useEffectOnce(() => {
    api.interceptors.response.use(
      (response: AxiosResponse) => response,
      (error) => {
        if (error?.response?.status === 401) {
          toast.error('Sessão expirada!');
          logout();
          return Promise.resolve(error);
        }
        return Promise.reject(error);
      },
    );
  });

  return (
    <AuthContext.Provider value={{ signed: !!(user && token), user, isLoading, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextData => {
  const context = useContext(AuthContext);

  return context;
};
