import { IconButton as Btn } from '@material-ui/core';
import styled from 'styled-components';

interface ButtonProps {
  bgcolor?: string;
  textcolor?: string;
}

export default styled(Btn)<ButtonProps>`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin-right: 5px;
  border-radius: 30%;
  box-shadow: ${(props) =>
    props.bgcolor
      ? `0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12)`
      : 'none'};
  background-color: ${(props) => props.bgcolor || 'transparent'};
  color: ${(props) => props.textcolor || props.theme.colors.text} !important;
  :hover {
    background-color: ${(props) => props.bgcolor || 'transparent'} !important;
    filter: brightness(90%);
  }
`;
