import React, { useMemo, useState, useContext } from 'react';
import { FiEdit, FiX } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Tooltip } from '@material-ui/core';
import { ThemeContext } from 'styled-components';
import { mutate } from 'swr';

import {
  Container,
  Button,
  ModalConfirm,
  Header,
  ButtonGroup,
  NavButton,
  IconButton,
} from '../../../components';
import Table, { Column } from '../../../components/Table';
import { useLoading } from '../../../contexts/loading';
import useFetch from '../../../hooks/useFetch';
import api from '../../../services/api';
import { configCurrency, putZero } from '../../../utils/constants';

const List: React.FC = () => {
  const [page, setPage] = useState(0);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [selectedGain, setSelectedGain] = useState('');

  const { data: gains } = useFetch<{ id: string; name: string }[]>('gains', {
    suspense: true,
  });

  const history = useHistory();

  const { onLoading } = useLoading();

  const {
    colors: { primary, error },
  } = useContext(ThemeContext);

  const handleChangePage = (_: any, newPage: number) => {
    setPage(newPage);
  };

  const handleNavigation = () => {
    history.push('/gains/create');
  };

  const handleClickOpenConfirmation = (id: string) => {
    setOpenConfirmation(true);
    setSelectedGain(id);
  };

  const handleClose = () => {
    setOpenConfirmation(false);
    setSelectedGain('');
  };

  const handleRemove = async () => {
    try {
      onLoading(true);

      await api.delete(`gains/${selectedGain}`);
      await mutate('gains');

      onLoading(false);
      toast.success('Deletado com sucesso!');
      handleClose();
    } catch (err) {
      handleClose();
      onLoading(false);
      toast.error(`Falha ao salvar! Tente novamente! ${err}`);
    }
  };

  const columns = useMemo<Column[]>(
    () => [
      { key: 'description', name: 'Descrição', sort: true },
      {
        key: 'value',
        name: 'Valor',
        sort: true,
        accessor: ({ value }) => new Intl.NumberFormat('pt-Br', configCurrency).format(value),
      },
      {
        key: 'date',
        name: 'Data',
        sort: true,
        accessor: ({ date }) =>
          `${putZero(new Date(date).getDate())}/${putZero(new Date(date).getMonth())}/${new Date(
            date,
          ).getFullYear()}`,
      },
      {
        key: 'actions',
        name: '',
        accessor: ({ id }) => (
          <ButtonGroup>
            <Tooltip title="Editar ganho">
              <NavButton to={`/gains/edit/${id}`} color={primary}>
                <FiEdit />
              </NavButton>
            </Tooltip>
            <Tooltip title="Deletar ganho">
              <div>
                <IconButton onClick={() => handleClickOpenConfirmation(id)} textcolor={error}>
                  <FiX />
                </IconButton>
              </div>
            </Tooltip>
          </ButtonGroup>
        ),
      },
    ],
    [error, primary],
  );

  return (
    <Container>
      <Header title="Ganhos">
        <Button variant="contained" onClick={handleNavigation}>
          Novo
        </Button>
      </Header>

      <Table
        rows={gains || []}
        columns={columns}
        page={page}
        count={gains?.length || 0}
        onChangePage={handleChangePage}
        pagination
      />

      <ModalConfirm
        title="Deletar Ganho"
        content="Deseja mesmo deletar essa ganho?"
        open={openConfirmation}
        onSuccess={handleRemove}
        onClose={handleClose}
      />
    </Container>
  );
};

export default List;
