import { FormControl as FormControlMU, Checkbox as CheckboxMU } from '@material-ui/core';
import styled from 'styled-components';

export const Checkbox = styled(CheckboxMU)`
  color: ${(props) => props.theme.colors.primary} !important;
`;

export const Title = styled.h1``;

export const FormFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 32px;
`;

export const FormControl = styled(FormControlMU)`
  min-width: 210px;
  max-width: 210px;
`;

export const Result = styled.div`
  margin-top: 3rem;
`;

export const TableContainer = styled.div`
  overflow: auto;

  .MuiTable-root {
    min-width: 100%;
    width: max-content;
  }
`;
