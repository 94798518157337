import { Link } from 'react-router-dom';

import styled from 'styled-components';

export default styled(Link)`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin-right: 5px;
  border-radius: 30%;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  background-color: ${(props) => props.color || props.theme.colors.primary};
  color: #fff;
  :hover {
    filter: brightness(90%);
  }
`;
