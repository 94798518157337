import React, { ChangeEvent, useCallback, useEffect, useMemo, useState, useContext } from 'react';
import { FiEdit, FiX } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Input,
  ListItemText,
  Tooltip,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { ThemeContext } from 'styled-components';
import { mutate } from 'swr';

import {
  Container,
  Button,
  ModalConfirm,
  Header,
  ButtonGroup,
  NavButton,
  IconButton,
} from '../../../components';
import Table, { Column } from '../../../components/Table';
import { useLoading } from '../../../contexts/loading';
import useFetch from '../../../hooks/useFetch';
import api from '../../../services/api';

import { Checkbox, FormControl } from './styles';

const List: React.FC = () => {
  const [refDate, setRefDate] = useState(new Date());
  const [selectedUnits, setSelectedUnits] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [selectedTask, setSelectedTask] = useState('');

  const { data: units } = useFetch<{ id: string; name: string }[]>('units', {
    suspense: true,
  });

  const { data: tasks } = useFetch<{ id: string; title: string }[]>(
    selectedUnits.length && refDate
      ? `tasks/list?date=${refDate.toISOString()}&unit=${selectedUnits.join(',')}`
      : null,
  );

  const history = useHistory();

  const { onLoading } = useLoading();

  const {
    colors: { primary, error },
  } = useContext(ThemeContext);

  const handleChangeDate = async (date: Date) => {
    setRefDate(date);
  };

  const handleChangeUnits = useCallback(
    (e: ChangeEvent<{ value: unknown }>) => {
      const values = e.target.value as string[];
      const allValues = units?.map((u) => u.id) || [];

      if (values.includes('-1') && values.length >= allValues.length) setSelectedUnits([]);
      else if (values.includes('-1')) setSelectedUnits(allValues);
      else setSelectedUnits(values);
    },
    [units],
  );

  const handleChangePage = (_: any, newPage: number) => {
    setPage(newPage);
  };

  const handleNavigation = () => {
    history.push('/tasks/create');
  };

  const handleClickOpenConfirmation = (id: string) => {
    setOpenConfirmation(true);
    setSelectedTask(id);
  };

  const handleClose = () => {
    setOpenConfirmation(false);
    setSelectedTask('');
  };

  const handleRemove = async () => {
    try {
      onLoading(true);

      await api.delete(`tasks/${selectedTask}`);
      await mutate(`tasks/list?date=${refDate.toISOString()}&unit=${selectedUnits.join(',')}`);

      onLoading(false);
      toast.success('Deletado com sucesso!');
      handleClose();
    } catch (err) {
      handleClose();
      onLoading(false);
      toast.error(`Falha ao salvar! Tente novamente! ${err}`);
    }
  };

  useEffect(() => {
    const allValues = units?.map((u) => u.id) || [];
    setSelectedUnits(allValues);
  }, [units]);

  const columns = useMemo<Column[]>(
    () => [
      { key: 'title', name: 'Título', sort: true },
      { key: 'description', name: 'Descrição', sort: true },
      {
        key: 'units',
        name: 'Unidades',
        accessor: ({ units: unis }) => (
          <span>{unis?.map((u: { name: string }) => u.name).join(', ')}</span>
        ),
      },
      {
        key: 'actions',
        name: '',
        accessor: ({ id }) => (
          <ButtonGroup>
            <Tooltip title="Editar atividade">
              <NavButton to={`/tasks/edit/${id}`} color={primary}>
                <FiEdit />
              </NavButton>
            </Tooltip>
            <Tooltip title="Deletar atividade">
              <div>
                <IconButton onClick={() => handleClickOpenConfirmation(id)} textcolor={error}>
                  <FiX />
                </IconButton>
              </div>
            </Tooltip>
          </ButtonGroup>
        ),
      },
    ],
    [error, primary],
  );

  return (
    <Container>
      <Header title="Atividades">
        <Button variant="contained" onClick={handleNavigation}>
          Novo
        </Button>
      </Header>

      <Grid container spacing={4}>
        <Grid item>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            id="contractSignDate"
            label="Data"
            value={refDate}
            onChange={(date) => handleChangeDate(date as Date)}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            required
            maxDate={new Date()}
          />
        </Grid>
        <Grid item>
          <FormControl variant="outlined">
            <InputLabel id="store">Unidade</InputLabel>
            <Select
              id="units"
              multiple
              value={selectedUnits}
              onChange={handleChangeUnits}
              input={<Input />}
              label="Unidade"
              renderValue={(selected) =>
                units
                  ?.filter((u) => (selected as string[]).some((sel) => sel === u.id))
                  .map((u) => u.name)
                  .join(', ')
              }
              required
            >
              <MenuItem value="-1">
                <Checkbox checked={selectedUnits.length === units?.length} />

                <ListItemText primary="Todas" />
              </MenuItem>

              {units?.map((st) => (
                <MenuItem value={st.id} key={st.id}>
                  <Checkbox checked={selectedUnits.includes(st.id)} />

                  <ListItemText primary={st.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <br />

      <Table
        rows={tasks || []}
        columns={columns}
        page={page}
        count={tasks?.length || 0}
        onChangePage={handleChangePage}
        pagination
      />

      <ModalConfirm
        title="Deletar Atividade"
        content="Deseja mesmo deletar essa atividade?"
        open={openConfirmation}
        onSuccess={handleRemove}
        onClose={handleClose}
      />
    </Container>
  );
};

export default List;
