import { IconType } from 'react-icons';
import { FiBriefcase, FiHome, FiList, FiUsers, FiDollarSign } from 'react-icons/fi';

import { Roles } from '../@types/user.d';
import { useAuth } from '../contexts/auth';

interface Screen {
  name: string;
  path: string;
  Icon: IconType;
}

const home = { name: 'Início', path: '/home/', Icon: FiHome };
const tasks = { name: 'Atividades', path: '/tasks/', Icon: FiList };
const units = { name: 'Unidades', path: '/units/', Icon: FiBriefcase };
const users = { name: 'Usuário', path: '/users/', Icon: FiUsers };
const gains = { name: 'Ganhos', path: '/gains/', Icon: FiDollarSign };

function useMenu(): Screen[] {
  const { user } = useAuth();

  const unit = [home, tasks, users];
  const students = [home, gains, tasks];
  const admin = [home, units, tasks, users];

  if (user?.role === Roles.ADMIN) return admin;
  if (user?.role === Roles.STUDENT) return students;
  if (user?.role === Roles.UNIT) return unit;

  return [];
}

export default useMenu;
