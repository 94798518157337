import { Drawer } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Drawer)`
  div.MuiDrawer-paper {
    position: relative;
    width: 72px;
    overflow-x: hidden;
    transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    white-space: nowrap;
    background: ${(props) => props.theme.colors.secondaryBackground};
    color: ${(props) => props.theme.colors.text};
    border-color: ${(props) => props.theme.colors.primary};

    @media (max-width: 600px) {
      width: 0px;
    }
  }

  ${({ open }) =>
    open
      ? `
        div.MuiDrawer-paper {
          width: 260px;
          transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
        }
      `
      : `
      div.MuiListItemIcon-root {
        margin-left: 20%;
      }`}
`;

export const ToolbarIcon = styled.div`
  display: flex;
  padding: 0 8px;
  min-height: 56px;
  align-items: center;
  justify-content: flex-end;

  background: ${(props) => props.theme.colors.primary};

  @media (min-width: 0px) and (orientation: landscape) {
    min-height: 68px;
  }

  @media (min-width: 600px) {
    min-height: 64px;
  }

  img {
    width: calc(100% - 68px);
  }

  svg {
    color: ${(props) => props.theme.colors.lightText};
  }
`;
