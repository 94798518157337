import React, { useMemo, useState, useContext } from 'react';
import { FiEdit, FiX } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Tooltip } from '@material-ui/core';
import { ThemeContext } from 'styled-components';
import { mutate } from 'swr';

import {
  Container,
  Button,
  ModalConfirm,
  Header,
  ButtonGroup,
  NavButton,
  IconButton,
} from '../../../components';
import Table, { Column } from '../../../components/Table';
import { useLoading } from '../../../contexts/loading';
import useFetch from '../../../hooks/useFetch';
import api from '../../../services/api';

const List: React.FC = () => {
  const [page, setPage] = useState(0);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [selectedUser, setSelectedUser] = useState('');

  const { data: users } = useFetch<{ id: string; name: string }[]>('users', {
    suspense: true,
  });

  const history = useHistory();

  const { onLoading } = useLoading();

  const {
    colors: { primary, error },
  } = useContext(ThemeContext);

  const handleChangePage = (_: any, newPage: number) => {
    setPage(newPage);
  };

  const handleNavigation = () => {
    history.push('/users/create');
  };

  const handleClickOpenConfirmation = (id: string) => {
    setOpenConfirmation(true);
    setSelectedUser(id);
  };

  const handleClose = () => {
    setOpenConfirmation(false);
    setSelectedUser('');
  };

  const handleRemove = async () => {
    try {
      onLoading(true);

      await api.delete(`users/${selectedUser}`);
      await mutate(`users`);

      onLoading(false);
      toast.success('Deletado com sucesso!');
      handleClose();
    } catch (err) {
      handleClose();
      onLoading(false);
      toast.error(`Falha ao salvar! Tente novamente! ${err}`);
    }
  };

  const columns = useMemo<Column[]>(
    () => [
      { key: 'name', name: 'Título', sort: true },
      { key: 'email', name: 'E-mail', sort: true },
      {
        key: 'units',
        name: 'Unidades',
        accessor: ({ units: unis }) => (
          <span>{unis?.map((u: { name: string }) => u.name).join(', ')}</span>
        ),
      },
      {
        key: 'actions',
        name: '',
        accessor: ({ id }) => (
          <ButtonGroup>
            <Tooltip title="Editar usuário">
              <NavButton to={`/users/edit/${id}`} color={primary}>
                <FiEdit />
              </NavButton>
            </Tooltip>
            <Tooltip title="Deletar usuário">
              <div>
                <IconButton onClick={() => handleClickOpenConfirmation(id)} textcolor={error}>
                  <FiX />
                </IconButton>
              </div>
            </Tooltip>
          </ButtonGroup>
        ),
      },
    ],
    [error, primary],
  );

  return (
    <Container>
      <Header title="Usuários">
        <Button variant="contained" onClick={handleNavigation}>
          Novo
        </Button>
      </Header>

      <Table
        rows={users || []}
        columns={columns}
        page={page}
        count={users?.length || 0}
        onChangePage={handleChangePage}
        pagination
      />

      <ModalConfirm
        title="Deletar registro"
        content="Deseja mesmo deletar esse registro?"
        open={openConfirmation}
        onSuccess={handleRemove}
        onClose={handleClose}
      />
    </Container>
  );
};

export default List;
